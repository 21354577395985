import React from 'react';
import { Typography, Box, Container } from '@mui/material';

const FinalScreen = () => {
  return (
    <Container>
      <Box textAlign="center" py={5}>
        <Typography variant="h4" gutterBottom>Congratulations!</Typography>
        <Typography variant="body1" gutterBottom>
          You have successfully completed the setup process. Welcome aboard!
        </Typography>
      </Box>
    </Container>
  );
};

export default FinalScreen;
