import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DiApple } from "react-icons/di";
import { FaGoogle } from "react-icons/fa";
import { useAuth } from '../../AuthContext';
import countries from '../countries.json';
import ReCAPTCHA from 'react-google-recaptcha';

const SignUpPage = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [country, setCountry] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [timer, setTimer] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [isEmailEditable, setIsEmailEditable] = useState(false);
  const { signup } = useAuth();
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const recaptchaSiteKey = "6LdaKOQpAAAAAINUqoJl8kmVHD6sZfEBKRI0wlRl";
  const backendDomain = 'https://api.hortonappworks.com:3000';

  useEffect(() => {
    const countdown = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [timer]);

  const handleResendVerification = async () => {
    try {
      await axios.post(`${backendDomain}/resend-verification`, { email });
      setTimer(120); // Set timer for 2 minutes
    } catch (error) {
      setErrorMessage("Failed to resend verification email.");
    }
  };

  const handleSignUpSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }
    if (!recaptchaToken) {
      setErrorMessage("Please complete the reCAPTCHA.");
      return;
    }
    try {
      const response = await signup({ fullName, email, password, country, recaptchaToken });
      setErrorMessage(response.msg);
      if (response.success) {
        setShowPopup(true);
        navigate('/email-verification');
      }
    } catch (error) {
      const errorMsg = error.response?.data?.msg || "An error occurred while processing your request.";
      setErrorMessage(errorMsg);
    }
  };

  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };


  // Apple Sign-in function
  const handleAppleSignIn = () => {
    const authOptions = {
      clientId: 'com.hortonappworks.account', // Your client ID obtained from Apple Developer portal
      redirectURI: 'account.hortonappworks.com/account', // Your redirect URI
      responseType: 'code id_token',
      scope: 'email name',
    };

    window.AppleID.auth.init(authOptions);
    window.AppleID.auth.signIn();
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <form className="w-full max-w-md bg-gray-900 p-8 rounded-lg" onSubmit={handleSignUpSubmit}>
        <h2 className="text-2xl font-bold mb-4 text-white">Create an account</h2>
        <div className="mb-4">
          <label className="block text-sm text-white mb-2" htmlFor="full_name">Full name</label>
          <input
            className="w-full px-3 py-2 bg-gray-800 text-white rounded-md focus:outline-none focus:bg-gray-700"
            type="text"
            id="full_name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            placeholder="Enter your name"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm text-white mb-2" htmlFor="country">Country</label>
          <select
            className="w-full px-3 py-2 bg-gray-800 text-white rounded-md focus:outline-none focus:bg-gray-700"
            id="country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            required
          >
            <option value="">Select your country</option>
            {countries.map((country) => (
              <option key={country.code} value={country.name}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm text-white mb-2" htmlFor="email">Email</label>
          <input
            className="w-full px-3 py-2 bg-gray-800 text-white rounded-md focus:outline-none focus:bg-gray-700"
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm text-white mb-2" htmlFor="password">Password</label>
          <input
            className="w-full px-3 py-2 bg-gray-800 text-white rounded-md focus:outline-none focus:bg-gray-700"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Create a password"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm text-white mb-2" htmlFor="confirm_password">Confirm Password</label>
          <input
            className="w-full px-3 py-2 bg-gray-800 text-white rounded-md focus:outline-none focus:bg-gray-700"
            type="password"
            id="confirm_password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm your password"
            required
          />
        </div>
        <div className="mb-4">
          <ReCAPTCHA
            sitekey={recaptchaSiteKey}
            onChange={onRecaptchaChange}
          />
        </div>
        {errorMessage && <div className="text-red-500 text-sm mb-2">{errorMessage}</div>}
        <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full transition-all duration-300 w-full">
          Sign Up
        </button>
        <div className="mt-4 text-center">
          <Link to="/signin" className="text-blue-400 hover:underline">Already have an account? Sign In</Link>
        </div>
        <button 
          className="bg-black text-white font-bold py-2 px-4 rounded-full mt-4 w-full relative flex items-center justify-center"
          onClick={handleAppleSignIn}
        >
          <DiApple size={24} />
          <span className="ml-2">Continue with Apple</span>
        </button>
      </form>
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
<div className="bg-white p-8 rounded-lg max-w-md w-full">
  <h2 className="text-2xl text-black font-bold mb-4">Email Verification Required</h2>
  <p className="mb-4 text-black">Please verify your email to complete the sign-up process. A verification link has been sent to your email address.</p>
  <div className="mb-4">
    <label className="block text-sm mb-2 text-black" htmlFor="verification-email">Email</label>
    <input
      className="w-full px-3 py-2 bg-gray-200 text-gray-700 rounded-md focus:outline-none"
      type="email"
      id="verification-email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      disabled={!isEmailEditable}
    />
    <button
      type="button"
      className="text-blue-500 hover:underline mt-2"
      onClick={() => setIsEmailEditable(!isEmailEditable)}
    >
      {isEmailEditable ? "Save Email" : "Change Email"}
    </button>
  </div>
  {timer > 0 ? (
    <div className="text-center text-gray-700">
      Resend verification email in {timer} seconds
    </div>
  ) : (
    <button
      type="button"
      className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full w-full"
      onClick={handleResendVerification}
    >
      Resend Verification Email
    </button>
  )}
  <button
    type="button"
    className="mt-4 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-full w-full"
    onClick={() => setShowPopup(false)}
  >
    Close
  </button>
</div>
</div>
)}
</div>
);
}
export default SignUpPage;