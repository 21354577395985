import React from 'react';
import { Button, Typography, Box, Container } from '@mui/material';

const WelcomeScreen = ({ onNext }) => {
  return (
    <Container>
      <Box textAlign="center" py={5}>
        <Typography variant="h4" gutterBottom>Welcome to Our Platform!</Typography>
        <Typography variant="body1" gutterBottom>
          We're excited to have you on board. Let's get started with some quick setup steps.
        </Typography>
        <Box mt={4}>
          <Button onClick={onNext} variant="contained" color="primary">
            Get Started
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default WelcomeScreen;
