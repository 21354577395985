// NotFoundPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import 'tailwindcss/tailwind.css';

function NotFoundPage() {
  return (
    <div className="bg-black text-white flex flex-col min-h-screen">
      <header className="bg-black-900 text-center py-4">
      </header>
      <main className="flex-grow flex flex-col items-center justify-center text-center">
        <h1 className="text-5xl">404</h1>
        <p className="text-gray-400 text-xl mt-4">Sorry, the page you're looking for cannot be found.</p>
        <Link to="/" className="mt-8 bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-full transition-all duration-300 no-underline">
          Go Home
        </Link>
      </main>
    </div>
  );
}

export default NotFoundPage;
