import React from 'react';
import { Link } from 'react-router-dom';
import { FiLock } from 'react-icons/fi';
import { FaMobileAlt, FaEnvelope, FaShieldAlt } from 'react-icons/fa';

const TwoFactorActivationPage = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-black text-white">
      <div className="max-w-md w-full px-8 py-12 bg-gray-900 rounded-lg shadow-lg text-center">
        <div className="text-4xl text-blue-500 mb-8 flex justify-center">
          <FiLock />
        </div>√
        <h2 className="text-2xl font-bold mb-4">Activate Two-Factor Authentication</h2>
        <p className="text-gray-400 mb-6">
          Enhance your account security by enabling two-factor authentication.
        </p>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          <ActivationOption
            icon={<FaMobileAlt className="text-4xl text-blue-500 mb-2" />}
            title="SMS Code"
            description="Receive codes via SMS"
            learnMoreLink="#"
          />
          <ActivationOption
            icon={<FaEnvelope className="text-4xl text-blue-500 mb-2" />}
            title="Email Code"
            description="Receive codes via email"
            learnMoreLink="#"
          />
          <ActivationOption
            icon={<FaShieldAlt className="text-4xl text-blue-500 mb-2" />}
            title="Authenticator App"
            description="Use a third-party authenticator app"
            learnMoreLink="#"
          />
        </div>
      </div>
    </div>
  );
};

const ActivationOption = ({ icon, title, description, learnMoreLink }) => {
  return (
    <div className="bg-gray-800 rounded-lg p-6 flex flex-col justify-between items-center">
      <div>
        {icon}
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-400 mb-2">{description}</p>
        <Link to={learnMoreLink} className="text-blue-500 hover:underline">Learn More</Link>
      </div>
      <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-full mt-4">
        Activate
      </button>
    </div>
  );
};

export default TwoFactorActivationPage;
