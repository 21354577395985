import React from 'react';
import { Button, Typography, Box, Container, TextField, MenuItem } from '@mui/material';

const CompanyInformation = ({ onNext, onBack }) => {
  return (
    <Container>
      <Box textAlign="center" py={5}>
        <Typography variant="h4" gutterBottom sx={{ color: 'white' }}>Company Information</Typography>
        <Typography variant="subtitle1" gutterBottom sx={{ color: 'white' }}>Please fill out the form below</Typography>
        <TextField 
          fullWidth 
          label="Company Name" 
          margin="normal" 
          variant="outlined" 
          InputLabelProps={{ style: { color: 'white' } }} 
          InputProps={{ style: { color: 'white', borderColor: 'white' } }} 
          required 
        />
        <TextField
          fullWidth
          select
          label="Country"
          margin="normal"
          variant="outlined"
          defaultValue=""
          InputLabelProps={{ style: { color: 'white' } }}
          InputProps={{ style: { color: 'white', borderColor: 'white' } }}
          required
        >
          <MenuItem value="">Select Country</MenuItem>
          {/* Add more menu items based on available countries */}
        </TextField>
        <TextField
          fullWidth
          select
          label="Company Size"
          margin="normal"
          variant="outlined"
          defaultValue=""
          InputLabelProps={{ style: { color: 'white' } }}
          InputProps={{ style: { color: 'white', borderColor: 'white' } }}
        >
          <MenuItem value="">Select Company Size</MenuItem>
          <MenuItem value="1-5">1-5 Employees</MenuItem>
          <MenuItem value="10-25">10-25 Employees</MenuItem>
          <MenuItem value="50-100">50-100 Employees</MenuItem>
          <MenuItem value="100-250">100-250 Employees</MenuItem>
        </TextField>
        <TextField 
          fullWidth 
          label="Company Website" 
          margin="normal" 
          variant="outlined" 
          InputLabelProps={{ style: { color: 'white' } }} 
          InputProps={{ style: { color: 'white', borderColor: 'white' } }} 
        />
        <TextField 
          fullWidth 
          label="City" 
          margin="normal" 
          variant="outlined" 
          InputLabelProps={{ style: { color: 'white' } }} 
          InputProps={{ style: { color: 'white', borderColor: 'white' } }} 
        />
        <TextField 
          fullWidth 
          label="ZIP Code" 
          margin="normal" 
          variant="outlined" 
          InputLabelProps={{ style: { color: 'white' } }} 
          InputProps={{ style: { color: 'white', borderColor: 'white' } }} 
        />
        <TextField 
          fullWidth 
          label="Address" 
          margin="normal" 
          variant="outlined" 
          InputLabelProps={{ style: { color: 'white' } }} 
          InputProps={{ style: { color: 'white', borderColor: 'white' } }} 
        />
        <TextField 
          fullWidth 
          label="State" 
          margin="normal" 
          variant="outlined" 
          InputLabelProps={{ style: { color: 'white' } }} 
          InputProps={{ style: { color: 'white', borderColor: 'white' } }} 
        />
        <Box mt={4}>
          {onBack && (
            <Button onClick={onBack} color="secondary">
              Back
            </Button>
          )}
          <Button onClick={onNext} variant="contained" color="primary" sx={{ ml: 2 }}>
            Continue
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default CompanyInformation;