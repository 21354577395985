import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DiApple } from "react-icons/di";
import { FaGoogle } from "react-icons/fa";
import { useAuth } from '../../AuthContext';
import ReCAPTCHA from 'react-google-recaptcha';

const SignInPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [timer, setTimer] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [isEmailEditable, setIsEmailEditable] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  const recaptchaSiteKey = "6LdaKOQpAAAAAINUqoJl8kmVHD6sZfEBKRI0wlRl";
  const backendDomain = 'https://api.hortonappworks.com:3000';

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [timer]);

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    if (!recaptchaToken) {
      setErrorMessage("Please complete the reCAPTCHA.");
      return;
    }
    try {
      const { token, verified } = await login(email, password, recaptchaToken);
      if (!verified) {
        setShowPopup(true);
      } else {
        navigate('/account');
      }
    } catch (error) {
      if (error.response?.data?.msg === "Email not verified. Please check your email for the verification link.") {
        setShowPopup(true);
      } else {
        navigate('/account');
        setErrorMessage(error.response?.data?.msg || "An error occurred while processing your request.");
      }
    }
  };

  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleResendVerification = async () => {
    try {
      await axios.post(`${backendDomain}/resend-verification`, { email });
      setTimer(60); // Set timer for 60 seconds
    } catch (error) {
      setErrorMessage("Failed to resend verification email.");
    }
  };


  // Apple Sign-in function
  const handleAppleSignIn = () => {
    const authOptions = {
      clientId: 'com.hortonappworks.account', // Your client ID obtained from Apple Developer portal
      redirectURI: 'account.hortonappworks.com/account', // Your redirect URI
      responseType: 'code id_token',
      scope: 'email name',
    };

    window.AppleID.auth.init(authOptions);
    window.AppleID.auth.signIn();
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900">
      <form className="w-full max-w-md bg-gray-800 p-8 rounded-lg" onSubmit={handleLoginSubmit}>
        <h2 className="text-2xl font-bold mb-4 text-white">Sign In</h2>
        <div className="mb-4">
          <label className="block text-sm text-white mb-2" htmlFor="email">Email</label>
          <input
            className="w-full px-3 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:bg-gray-600"
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm text-white mb-2" htmlFor="password">Password</label>
          <input
            className="w-full px-3 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:bg-gray-600"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            required
          />
        </div>
        <div className="mb-4">
          <ReCAPTCHA
            sitekey={recaptchaSiteKey}
            onChange={onRecaptchaChange}
          />
        </div>
        {errorMessage && <div className="text-red-500 text-sm mb-2">{errorMessage}</div>}
        <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full transition-all duration-300 w-full">
          Sign In
        </button>
        <div className="mt-4 text-center">
          <Link to="/forgot-password" className="text-blue-400 hover:underline">Forgot your password?</Link>
        </div>
        <div className="mt-4 text-center">
          <Link to="/signup" className="text-blue-400 hover:underline">Don't have an account? Sign Up</Link>
        </div>
        <button 
  className="bg-black text-white font-bold py-2 px-4 rounded-full mt-4 w-full relative flex items-center justify-center hover:bg-gray-900"
  onClick={handleAppleSignIn}
>
  <DiApple size={24} />
  <span className="ml-2">Continue with Apple</span>
</button>

      </form>

      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg max-w-md w-full">
            <h2 className="text-2xl text-black font-bold mb-4">Email Verification Required</h2>
            <p className="mb-4 text-black">Please verify your email to continue. A verification link has been sent to your email address.</p>
            <div className="mb-4">
              <label className="block text-sm mb-2 text-black" htmlFor="verification-email">Email</label>
              <input
                className="w-full px-3 py-2 bg-gray-200 text-gray-700 rounded-md focus:outline-none"
                type="email"
                id="verification-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={!isEmailEditable}
              />
              <button
                type="button"
                className="text-blue-500 hover:underline mt-2"
                onClick={() => setIsEmailEditable(!isEmailEditable)}
              >
                {isEmailEditable ? "Save Email" : "Change Email"}
              </button>
            </div>
            {timer > 0 ? (
              <div className="text-center text-gray-700">
                Resend verification email in {timer} seconds
              </div>
            ) : (
              <button
                type="button"
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full w-full"
                onClick={handleResendVerification}
              >
                Resend Verification Email
              </button>
            )}
            <button
              type="button"
              className="mt-4 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-full w-full"
              onClick={() => setShowPopup(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignInPage;