// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white text-center py-4">
      <p className="text-sm">© 2024 Horton AppWorks. All rights reserved.</p>
      <div className="mt-2 flex justify-center">
        <a href="/privacy" className="text-blue-400 hover:underline mx-2">Privacy Policy</a>
        <a href="/terms" className="text-blue-400 hover:underline mx-2">Terms & Conditions</a>
      </div>
    </footer>
  );
};

export default Footer;