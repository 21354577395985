import React, { useState } from 'react';
import { Button, Typography, Box, Container, FormControlLabel, Checkbox } from '@mui/material';

const TermsOfService = ({ onNext }) => {
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    if (accepted) {
      onNext();
    }
  };

  return (
    <Container>
      <Box textAlign="center" py={5}>
        <Typography variant="h4" gutterBottom sx={{ color: 'white' }}>Terms of Service & Privacy Policy</Typography>
        <Typography variant="body1" sx={{ color: 'white' }}>
          Please read and accept our terms of service and privacy policy to continue.
        </Typography>
        <Box mt={3} textAlign="left">
          <Typography variant="body2" sx={{ color: 'white' }}>
            [Insert Terms of Service Text Here]
          </Typography>
          <Typography variant="body2" sx={{ color: 'white', mt: 2 }}>
            [Insert Privacy Policy Text Here]
          </Typography>
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={accepted}
              onChange={(e) => setAccepted(e.target.checked)}
              color="primary"
              sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }}
            />
          }
          label={<Typography sx={{ color: 'white' }}>I accept the Terms of Service and Privacy Policy</Typography>}
        />
        <Box mt={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAccept}
            disabled={!accepted}
            
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default TermsOfService;