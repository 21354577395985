import React, { useState } from 'react';
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import WelcomeScreen from './WelcomeScreen';
import TermsOfService from '../TermsOfServce';
import CompanyInformation from './CompanyInformation';
import PersonalInformation from './PersonalInformation';
import FinalScreen from './FinalWelcomeScreen';

function getSteps() {
  return ['Welcome', 'Legal' ,'Company Information', '2 Factor Authentication', 'Complete Setup'];
}

function getStepContent(stepIndex, handleNext, handleBack) {
  switch (stepIndex) {
    case 0:
      return <WelcomeScreen onNext={handleNext} />;
    case 1:
      return <TermsOfService onNext={handleNext} onBack={handleBack} />;
    case 2:
      return <CompanyInformation onNext={handleNext} onBack={handleBack} />;
    case 3:
      return <FinalScreen onNext={handleNext} />;
    default:
      throw new Error('Unknown step');
  }
}

export default function Onboarding() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {getStepContent(activeStep, handleNext, handleBack)}
      </div>
    </Box>
  );
}
