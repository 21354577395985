import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const AccountPage = () => {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to="/signin" />;
  }

  return (
    <>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">Account Settings</h1>
        {/* Sample settings sections */}
        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Profile Settings</h2>
          {/* Profile settings options */}
        </div>
        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Security Settings</h2>
          {/* Security settings options */}
        </div>
        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Billing Settings</h2>
          {/* Billing settings options */}
        </div>
      </div>
    </>
  );
};

export default AccountPage;
