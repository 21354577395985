import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('userToken'));
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const backendDomain = 'https://api.hortonappworks.com:3000';

  const login = async (email, password, recaptchaToken) => {
    const response = await axios.post(
      `${backendDomain}/api/auth/login`,
      { email, password, recaptchaToken },
      { headers: { 'Content-Type': 'application/json' } }
    );

    const { token, verified } = response.data;
    localStorage.setItem('userToken', token);
    setIsLoggedIn(true);
    setIsEmailVerified(verified);
  };

  const signup = async ({ fullName, email, password, country, recaptchaToken }) => {
    try {
      const response = await axios.post(`${backendDomain}/api/auth/signup`, {
        fullName, email, password, country, recaptchaToken
      });
      return { success: true, msg: response.data.msg, userId: response.data.userId };
    } catch (error) {
      return { success: false, msg: error.response?.data?.msg || "An error occurred." };
    }
  };

  const checkEmailVerification = async () => {
    const token = localStorage.getItem('userToken');
    if (!token) return false;

    const response = await axios.get(`${backendDomain}/api/auth/check-verification`, {
      headers: { Authorization: `Bearer ${token}` }
    });

    setIsEmailVerified(response.data.verified);
    return response.data.verified;
  };

  const logout = () => {
    localStorage.removeItem('userToken');
    setIsLoggedIn(false);
    setIsEmailVerified(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, isEmailVerified, login, signup, checkEmailVerification, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
