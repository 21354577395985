import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useAuth } from '../AuthContext';

const EmailVerificationPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated, isEmailVerified, checkEmailVerification } = useAuth();
  const [message, setMessage] = useState('');
  const [timer, setTimer] = useState(0);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const backendDomain = 'https://api.hortonappworks.com:3000';
  const recaptchaSiteKey = "6LdaKOQpAAAAAINUqoJl8kmVHD6sZfEBKRI0wlRl";

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/signin');
    } else {
      const verifyEmailStatus = async () => {
        const verified = await checkEmailVerification();
        if (verified) {
          navigate('/account');
        }
      };
      verifyEmailStatus();
    }
  }, [isAuthenticated, checkEmailVerification, navigate]);

  useEffect(() => {
    const timerId = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  const handleResendVerification = async () => {
    try {
      await axios.post(`${backendDomain}/api/auth/resend-verification-email`, { recaptchaToken });
      setMessage('Verification email resent successfully!');
    } catch (error) {
      console.error('Error resending verification email:', error.message);
      setMessage('Failed to resend verification email.');
    }
  };

  const recaptchaOnChange = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-black text-white">
      <div className="max-w-md w-full px-8 py-12 bg-gray-900 rounded-lg shadow-lg text-center">
        <h2 className="text-2xl font-bold mb-4">Email Verification</h2>
        <p className="text-gray-400 mb-4">
          Please verify your email address by clicking the link we sent to your email.
        </p>
        {message && <p className="text-green-400 mb-4">{message}</p>}
        <div className="mb-4">
          <ReCAPTCHA
            sitekey={recaptchaSiteKey}
            onChange={recaptchaOnChange}
          />
        </div>
        <p className="text-gray-400 mb-4">
          Resend verification email (Last sent {timer > 0 ? timer + 's ago' : 'now'}):
          <button
            className="ml-2 text-blue-500 hover:underline"
            onClick={handleResendVerification}
            disabled={timer < 120}
          >
            Resend
          </button>
        </p>
      </div>
    </div>
  );
};

export default EmailVerificationPage;
