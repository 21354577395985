import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import SignInPage from './Components/LoginSignup/SignInPage';
import SignUpPage from './Components/LoginSignup/SignUpPage';
import AccountPage from './Components/AccountPage';
import EmailVerificationPage from './Components/EmailVerificationPage';
import ForgotPasswordPage from './Components/ForgotPasswordPage';
import ResetPasswordPage from './Components/ResetPasswordPage';
import EmailVerificationLinkPage from './Components/EmailVerificationLinkPage';
import Header from './Components/Header';
import Footer from './Components/Footer';
import TwoFactorActivationPage from './Components/TwoFactorActivationPage'; 
import NotFoundPage from './Components/NotFoundPage';
import 'tailwindcss/tailwind.css';
import Onboarding from './Components/Onboarding/Onboarding';
import Loader from './Loader';

function HomeRedirect() {
  window.location.href = 'https://hortonappworks.com';
  return null;
}

function RouterContent() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true); // State to track the initial load

  useEffect(() => {
    if (isInitialLoad) {
      const timer = setTimeout(() => {
        setIsInitialLoad(false); 
      }, 150); // fade-in effect duration
      return () => clearTimeout(timer);
    } else {

      // For subsequent page navigations
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [location]);


  return (
    <div className={`bg-black text-white flex-col items-center justify-center transition-opacity duration-200 ease-in-out ${isInitialLoad ? 'opacity-0' : 'opacity-100'} bg-darker text-white min-h-screen`}>
    <Header />
    {loading && !isInitialLoad ? <Loader /> : (
        <Routes>
          <Route path="/" element={<SignInPage />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/verify-email" element={<EmailVerificationLinkPage />} />
          <Route path="/email-verification" element={<EmailVerificationPage />} />
          <Route path="/activate-2fa" element={<TwoFactorActivationPage />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/get-started" element={<Onboarding />} />
          <Route path="/home" element={<HomeRedirect />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      )}
      <Footer />
    </div>
  );
}

function App() {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <AuthProvider>
      <Router>
      <RouterContent isLoaded={isLoaded} />
      </Router>
    </AuthProvider>
  );
}

export default App;
