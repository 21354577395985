import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

const EmailVerificationLinkPage = () => {
  const [message, setMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const backendDomain = 'https://api.hortonappworks.com:3000';

  useEffect(() => {
    const verifyEmail = async () => {
      const token = new URLSearchParams(location.search).get('token');
      if (!token) {
        setMessage('Invalid verification link');
        return;
      }

      try {
        const response = await axios.get(`${backendDomain}/api/auth/verify-email?token=${token}`);
        setMessage(response.data.msg);
        setTimeout(() => {
          navigate('/signin');
        }, 3000);
      } catch (error) {
        setMessage(error.response.data.msg || 'An error occurred during verification');
      }
    };

    verifyEmail();
  }, [location, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-black text-white">
      <div className="max-w-md w-full px-8 py-12 bg-gray-900 rounded-lg shadow-lg text-center">
        <h2 className="text-2xl font-bold mb-4">Email Verification</h2>
        <p className="text-gray-400">{message}</p>
      </div>
    </div>
  );
};

export default EmailVerificationLinkPage;
