import React, { useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const ResetPasswordPage = () => {
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const location = useLocation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    try {
      const response = await axios.post('https://api.hortonappworks.com:3000/api/auth/reset-password', { token, newPassword });
      setMessage(response.data.msg);
    } catch (error) {
      setMessage(error.response.data.msg || 'An error occurred.');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <form className="w-full max-w-md p-8 bg-gray-900 rounded-lg" onSubmit={handleSubmit}>
        <h2 className="mb-4 text-2xl font-bold text-white">Set New Password</h2>
        <div className="mb-4">
          <label className="block mb-2 text-sm text-white" htmlFor="newPassword">New Password</label>
          <input
            type="password"
            id="newPassword"
            className="w-full px-3 py-2 bg-gray-800 text-white rounded-md focus:outline-none focus:bg-gray-700"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <button className="w-full py-2 px-4 text-white bg-blue-600 rounded-full hover:bg-blue-700 font-bold transition-all duration-300">Update Password</button>
        {message && <div className="mt-4 text-center text-sm text-white">{message}</div>}
      </form>
    </div>
  );
};

export default ResetPasswordPage;
