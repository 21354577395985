import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // Import useAuth hook

const Header = () => {
  const { isLoggedIn, logout } = useAuth(); // Access isLoggedIn and logout from AuthContext

  return (
    <nav className="bg-black text-white px-5 py-4 flex justify-between items-center">
      <div className="text-lg font-bold">
        Horton Account
      </div>
      <ul className="flex items-center space-x-8 flex-grow justify-center"> {/* Updated here */}
        <li><Link to="/home" className="hover:text-blue-400 transition-colors">Home</Link></li>
        <li><Link to="/account" className="hover:text-blue-400 transition-colors">Account</Link></li>
        <li><Link to="/" className="hover:text-blue-400 transition-colors">HortonSuite</Link></li>
      </ul>
      <div> {/* Added a div to hold the sign-in/sign-out button */}
        {isLoggedIn ? (
          <button className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-6 rounded-full transition-all duration-300" onClick={logout}>
            Sign Out
          </button>
        ) : (
          <Link to="/signin" className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-6 rounded-full transition-all duration-300">
            Sign In
          </Link>
        )}
      </div>
    </nav>
  );
};

export default Header;
